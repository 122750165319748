import React, { useContext, useEffect } from "react";
import UserContext from "../components/UserContext";
import { getHomePageContent } from "../lib/api";
import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
import Home from "./home";

export default function HomePage({ content }) {
  const { scrollRef } = useContext(UserContext);
  const liveContent = useContentfulLiveUpdates(content);
  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  });

  return (
    <>
      <Home content={liveContent} />
    </>
  );
}

export async function getStaticProps({ params, draftMode = false }) {
  const data = await getHomePageContent(params?.id, draftMode);
  return {
    props: {
      content: data.content ?? null,
      draftMode,
    },
    revalidate: 10,
  };
}
